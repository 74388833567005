import React, { createContext, useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { apiCall } from '../services/commonApi'
import { ENDPOINTS } from '../Api'
const CurrencyContext = createContext()

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState(() => {
    const currency = localStorage.getItem('currency')
    return currency ? currency : 'USD'
  })
  const [rates, setRates] = useState({})
  useEffect(() => {
    const fetchRates = async () => {
      const cachedRates = JSON.parse(localStorage.getItem('rates'))
      const cachedTime = localStorage.getItem('ratesTime')
      if (
        cachedRates &&
        cachedTime &&
        Date.now() - cachedTime < 60 * 60 * 1000
      ) {
        setRates(cachedRates)
      } else {
        try {
          let data = {
            source: 'USD',
            // currencies: 'EUR,USD,GBP,PLN',
            currencies: 'USD,GBP,INR,EUR,CNY,CAD,BRL,AUD,CHF',
            service_name: process.env.REACT_APP_CURRENCY_RATE_CONVERTER_API_KEY,
          }
          const response = await apiCall(
            'POST',
            ENDPOINTS.currencyRateConverter,
            data,
          )
          const trimmedCurrencyData = Object.fromEntries(
            Object.entries(response.data.quotes).map(([key, value]) => [
              key.substring(response.data.source.length),
              value,
            ]),
          )
          setRates(trimmedCurrencyData)
          localStorage.setItem('rates', JSON.stringify(trimmedCurrencyData))
          localStorage.setItem('ratesTime', Date.now())
        } catch (error) {
          console.error('Error fetching exchange rates:', error)
        }
      }
    }
    fetchRates()
    // const intervalId = setInterval(fetchRates, 60 * 60 * 1000)
    // return () => clearInterval(intervalId)
  }, [])

  return (
    <CurrencyContext.Provider value={{ currency, setCurrency, rates }}>
      {children}
    </CurrencyContext.Provider>
  )
}
export const useCurrency = () => useContext(CurrencyContext)
