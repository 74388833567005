import axios from 'axios'
import Cookie from 'js-cookie'

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})
export const apiCall = async (method, endpoint, data = null, params = {}) => {
  try {
    const config = {
      method: method,
      url: endpoint,
      params: params,
      data: data,
    }
    if (data instanceof FormData) {
      config.headers = {
        'Content-Type': 'multipart/form-data',
      }
    } else {
      // Set 'Content-Type' for JSON data
      config.headers = {
        'Content-Type': 'application/json',
      }
    }
    const token = Cookie.get('userToken')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    const response = await apiInstance(config)
    return response.data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      window.location.href = '/login'
    } else {
      throw error
    }
  }
}
const apiInstanceForBlob = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})
apiInstanceForBlob.interceptors.request.use(
  config => {
    const token = Cookie.get('userToken')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  error => {
    // Handle the error
    return Promise.reject(error)
  },
)

apiInstance.interceptors.response.use(
  response => response, // Pass successful responses as-is
  error => {
    if (error.response && error.response.status === 401) {
      // Optional: Redirect to the login page
      window.location.href = '/login' // Update with your login route
    }

    // Return a rejected promise to propagate the error
    return Promise.reject(error)
  },
)

export default apiInstanceForBlob
