import React from 'react'
import AppRoutes from './router' // Import the routes from router.js
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { CurrencyProvider } from './context/CurrencyContext'
import TokenRefresher from './layouts/TokenRefresher'
import InactivityLogout from './components/InActiveUser/InActiveUser'
import LogoutOnClose from './components/LogoutOnClose/LogOutOnClose'
import * as reactRouterDom from 'react-router-dom'

function App() {
  const location = reactRouterDom.useLocation() // Get current route
  return (
    <>
      <CurrencyProvider>
        <ToastContainer autoClose={5000} />
        <AppRoutes />
        {location.pathname !== '/login' ? <InactivityLogout /> : null}
        {location.pathname !== '/login' ? <LogoutOnClose /> : null}
        <TokenRefresher />
      </CurrencyProvider>
    </>
  )
}

export default App
