import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './i18n/i18n'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { LoadingProvider } from './context/LoadingContext'
import { AuthProvider } from './context/AuthContext'
import { BrowserRouter } from 'react-router-dom'
import TokenRefresher from './layouts/TokenRefresher'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <LoadingProvider>
          <App />
          <TokenRefresher />
        </LoadingProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
)
document.addEventListener('touchstart', () => {}, { passive: true })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
