import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { clearLoginToken } from '../../services/storage'

const InactivityLogout = () => {
  const [isActive, setIsActive] = useState(true)
  const INACTIVITY_LIMIT = 15 * 60 * 1000

  let logoutTimer

  // Resets the logout timer
  const resetTimer = () => {
    clearTimeout(logoutTimer)
    startLogoutTimer()
  }

  // Starts the inactivity timer
  const startLogoutTimer = () => {
    logoutTimer = setTimeout(() => {
      clearLoginToken()
    }, INACTIVITY_LIMIT)
  }

  // Monitors user activity
  const handleActivity = () => {
    resetTimer()
    if (!isActive) setIsActive(true) // Reactivate if the user returns
  }

  useEffect(() => {
    // Add event listeners for user activity
    window.addEventListener('mousemove', handleActivity)
    window.addEventListener('keydown', handleActivity)

    startLogoutTimer() // Start the logout timer on component mount

    return () => {
      // Cleanup event listeners and timers on component unmount
      window.removeEventListener('mousemove', handleActivity)
      window.removeEventListener('keydown', handleActivity)
      clearTimeout(logoutTimer)
    }
  }, [])

  return <></>
}

export default InactivityLogout
