import React, { useEffect } from 'react'
import { clearLoginToken } from '../../services/storage'

const LogOutOnClose = () => {
  useEffect(() => {
    if (!sessionStorage.getItem('isRefresh')) {
      sessionStorage.setItem('isRefresh', 'false')
    }

    const handleBeforeUnload = event => {
      sessionStorage.setItem('isRefresh', 'true')
    }

    const handleUnload = () => {
      if (sessionStorage.getItem('isRefresh') !== 'true') {
        clearLoginToken()
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('unload', handleUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('unload', handleUnload)
    }
  }, [])

  return <></>
}

export default LogOutOnClose
